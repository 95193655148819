<template>
  <div class="px-5">
    <div class="hidden-sm-and-down">
      <v-row>
        <v-col cols="5">
          <div class="text-h4 primary--text">รายงานสรุปยอดขายรวม</div>
        </v-col>
        <v-col cols="1"> </v-col>
        
        <v-col cols="2" class="text-right">
          <v-btn
            color="success"
            small
            outlined
            class="ml-5 px-5"
            @click="download_daily_summary_excel()"
          >
            ยอดขายรวมสรุปรายวัน <v-icon right small>mdi-download</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            color="success"
            small
            outlined
            class="ml-5 px-5"
            @click="download_detail_excel()"
          >
            ดาวน์โหลดแบบละเอียด <v-icon right small>mdi-download</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            color="success"
            small
            outlined
            class="ml-5 px-5"
            @click="download_excel()"
          >
            ดาวน์โหลด <v-icon right small>mdi-download</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-toolbar height="200px" dense flat rounded outlined class="my-5 pt-3 pb-15">
        <v-row>
          <v-col cols="2">
            <v-select
              label="ช่องทางการชำระ"
              :items="payment_type"
              item-value="value"
              item-text="text"
              outlined
              rounded
              dense
              hide-details
              v-model="filter.payment_type"
            >
            </v-select>
          </v-col>

          <v-col cols="2">
            <v-select
              label="ช่วงเวลา"
              v-model="timeRangeType"
              :items="timeRangeTypes"
              item-value="value"
              item-text="text"
              outlined
              rounded
              dense
              hide-details
            >
            </v-select>
          </v-col>

          <v-col cols="2">
            <DatePicker
              v-if="timeRangeType == 1"
              label="วันเริ่มต้น"
              :value="filter.start_date"
              @onChange="(val) => (filter.start_date = val)"
              hideDetails
              outlined
              rounded
              dense
            />

            <v-menu
              v-else-if="timeRangeType == 2"
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="filter.start_date ? getMonth(filter.start_date) : ''"
                  label="เดือน"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  hideDetails
                  outlined
                  rounded
                  dense
                  readonly
                >
                </v-text-field>
              </template>
              <v-date-picker
                class="hidden-sm-and-down"
                v-model="month"
                type="month"
                no-title
                scrollable
                locale="th"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="onSaveMonth(month)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="2">
            <DatePicker
              v-if="timeRangeType == 1"
              label="ถึงวันที่"
              :value="filter.end_date"
              @onChange="(val) => (filter.end_date = val)"
              hideDetails
              outlined
              rounded
              dense
            />
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="3" class="pb-8">
            <v-btn color="primary" small class="float-right" @click="getData()"
              >แสดงรายงาน <v-icon right>mdi-magnify</v-icon></v-btn
            >
          </v-col>
          <v-col cols="2">
              ตรวจ <b>{{ total_inspect_price | numeral('0,0.00') }}</b> บาท  
          </v-col>
           <v-col cols="2">
            พรบ. <b>{{ total_third_insurance_price | numeral('0,0.00') }}</b> บาท  
          </v-col>
          <v-col cols="2">
              ภาษี <b>{{ total_tax_price | numeral('0,0.00') }}</b> บาท  
          </v-col>
          <v-col cols="2">
              ประกัน <b>{{ total_insurance_price | numeral('0,0.00') }}</b> บาท  
          </v-col>
          <v-col cols="2">
              อื่นๆ <b>{{ total_other_product_price | numeral('0,0.00') }}</b> บาท  
          </v-col>
          <v-col cols="2">
              รวมทั้งหมด <b>{{ total_sales | numeral('0,0.00') }}</b>        
          </v-col>

          <v-col cols="2">
              รย.1 = <b>{{ total_ct1 }}</b> คัน
          </v-col>
          <v-col cols="2">
              รย.2 = <b>{{ total_ct2 }}</b> คัน
          </v-col>
          <v-col cols="2">
              รย.3 = <b>{{ total_ct3 }}</b> คัน
          </v-col>
          <v-col cols="2">
              รย.12 = <b>{{ total_ct4 }}</b> คัน
          </v-col>
          <v-col cols="2">
              รย.อื่นๆ = <b>{{ total_ct_other }}</b> คัน
          </v-col>
          <v-col cols="2">
              รวมรถทั้งหมด = <b>{{ total_ct1 + total_ct2 + total_ct3 + total_ct4 + total_ct_other }}</b> คัน
          </v-col>
        </v-row>
      </v-toolbar>
    </div>
    <div class="hidden-md-and-up">
      <v-row col="12">
        <div class="text-h6 primary--text">รายงานสรุปยอดขายรวม</div>
      </v-row>
      <v-row class="mb-3 " justify="end">
        <v-btn
          color="success"
          class="mr-1"
          small
          outlined
          @click="download_daily_summary_excel()"
        >
          ยอดขายรวมสรุปรายวัน <v-icon right small>mdi-download</v-icon>
        </v-btn>
        <v-btn
          color="success"
          class="mr-1"
          small
          outlined
          @click="download_detail_excel()"
        >
          ดาวน์โหลดแบบละเอียด <v-icon right small>mdi-download</v-icon>
        </v-btn>

        <v-btn
          color="success"
          small
          outlined
          @click="download_excel()"
        >
          ดาวน์โหลด <v-icon right small>mdi-download</v-icon>
        </v-btn>
      </v-row>
      <v-card outlined class="mb-5">
        <v-card-text>
          <v-row>
            <v-col cols="6" class="px-1">
              <v-select
                label="ช่องทางการชำระ"
                :items="payment_type"
                item-value="value"
                item-text="text"
                outlined
                rounded
                dense
                hide-details
                v-model="filter.payment_type"
              >
              </v-select>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-select
                label="ช่วงเวลา"
                v-model="timeRangeType"
                :items="timeRangeTypes"
                item-value="value"
                item-text="text"
                outlined
                rounded
                dense
                hide-details
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row v-if="timeRangeType == 1">
            <v-col cols="6" class="px-0">
              <DatePicker
                label="วันจดทะเบียน"
                :value="filter.start_date"
                @onChange="(val) => (filter.start_date = val)"
                hideDetails
                outlined
                rounded
                dense
            /></v-col>
            <v-col cols="6" class="px-0">
              <DatePicker
                label="ถึงวันที่"
                :value="filter.end_date"
                @onChange="(val) => (filter.end_date = val)"
                hideDetails
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>
          <v-row v-if="timeRangeType == 2">
            <v-col cols="6" class="px-1">
              <v-menu
                ref="menu2"
                v-model="menu2"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="month"
                    label="เดือน"
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    hideDetails
                    outlined
                    rounded
                    dense
                    readonly
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  class="hidden-md-and-up"
                  v-model="month"
                  type="month"
                  no-title
                  scrollable
                  locale="th"
                  @change="onSaveMonth(month)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-3">
              <v-btn
                color="primary"
                small
                class="float-right"
                @click="getData()"
              >
                แสดงรายงาน <v-icon right>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :search="search"
      :loading="loading"
      :loading-text="$table_loading_text"
      :page.sync="page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      item-key="_id"
      @click:row="edit"
    >
      <template v-slot:[`item._id`]="{ item }">
          {{
            list
              .map(function (x) {
                return x._id;
              })
              .indexOf(item._id) +
            1 +
            filter.item_per_page * (filter.page - 1)
          }}
      </template>
      <template v-slot:[`item.date_of_issue`]="{ item }">
        {{ formatDate(item.date_of_issue) }}
      </template>
      <template v-slot:[`item.inspect_price`]="{ item }">
        {{ item.inspect_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.third_insurance_price`]="{ item }">
        {{ item.third_insurance_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.tax_price`]="{ item }">
        {{ item.tax_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.insurance_price`]="{ item }">
        {{ item.insurance_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.other_product_price`]="{ item }">
        {{ item.other_product_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.net_price`]="{ item }">
        {{ item.net_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.payment_type`]="{ item }">
        {{ item.payment_type == 1 ? 'เงินสด' : '' }}
        {{ item.payment_type == 2 ? 'โอน' : '' }}
        {{ item.payment_type == 3 ? 'บัตรเครดิต' : '' }}
      </template>

      <template v-slot:footer>
        <div class="hidden-sm-and-down">
          <table-pagination-custom
            class="text-caption"
            :page="filter.page"
            :itemsPerPage="filter.item_per_page"
            :length="pageCount"
            @change-page="onChangePage"
            @change-items-per-page="onChangeitemsPerPage"
          />
        </div>
        <v-pagination
          class="hidden-md-and-up"
          v-model="filter.page"
          :length="pageCount"
          :total-visible="5"
          @input="onChangePage"
          circle
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import * as moment from 'moment';
import JsonExcel from 'vue-json-excel';

export default {
  components: {
    DatePicker,
  },

  data: () => ({
    total_inspect_price: 0,
    total_third_insurance_price: 0,
    total_tax_price: 0,
    total_insurance_price: 0,
    total_other_product_price: 0,
    total_sales: 0,
    total_ct1: 0,
    total_ct2: 0,
    total_ct3: 0,
    total_ct4: 0,
    total_ct_other: 0,
    total_car: 0,
    number_char_1: "",
    search: '',
    selected: [],
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 50,
    list: [],
    headers: [
      { text: '#', value: '_id', filterable: false, sortable: false, width: 0},
      { text: 'วันที่', value: 'date_of_issue' },
      { text: 'เลขที่เอกสาร', value: 'number', filterable: false },
      { text: 'ชื่อลูกค้า', value: 'customer_name' },
      { text: 'ชื่อโปรเจ็ค', value: 'project_name', filterable: false },
      { text: 'ตรวจ', value: 'inspect_price', filterable: false },
      { text: 'พรบ', value: 'third_insurance_price', filterable: false },
      { text: 'ภาษี', value: 'tax_price', filterable: false },
      { text: 'ประกัน', value: 'insurance_price', filterable: false },
      { text: 'อื่น ๆ', value: 'other_product_price', filterable: false },
      { text: 'ยอดรวมสุทธิ', value: 'net_price', filterable: false },
      { text: 'ช่องทาง', value: 'payment_type', filterable: false },
      { text: 'ธนาคาร', value: 'payment_reference', filterable: false },
    ],
    payment_type: [
      { text: 'ทั้งหมด', value: null },
      { text: 'เงินสด', value: 1 },
      { text: 'โอน', value: 2 },
      { text: 'บัตรเครดิต', value: 3 },
    ],
    timeRangeType: 3,
    timeRangeTypes: [
      { value: 3, text: 'วันนี้' },
      { value: 0, text: 'เดือนปัจจุบัน' },
      { value: 2, text: 'เดือนก่อน' },
      { value: 1, text: 'เลือกช่วงวันที่' },
    ],
    menu: false,
    menu2: false,
    month: null,
    filter: {
      item_per_page: 200,
      page: 1,
      branch_id: null,
      payment_type: null,
      start_date: moment().add(-0, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      // month: moment().format("YYYY-MM")
    },
  }),

  created() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },

  methods: {
    async getData() {
      this.loading = true;
      this.total_inspect_price = 0;
      this.total_third_insurance_price = 0;
      this.total_tax_price = 0;
      this.total_insurance_price = 0;
      this.total_other_product_price = 0;
      this.total_sales = 0;
      this.total_ct1 = 0;
      this.total_ct2 = 0;
      this.total_ct3 = 0;
      this.total_ct4 = 0;
      this.total_ct_other = 0;
      this.total_car = 0;
      this.number_char_1 = "";
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/report/total_sales_report`, body)
        .then((res) => {
          this.list = res.result.data;
          console.log(this.list);
          for (let i = 0; i < this.list.length; i++) {
            this.total_inspect_price += this.list[i].inspect_price;
            this.total_third_insurance_price += this.list[i].third_insurance_price;
            this.total_tax_price += this.list[i].tax_price;
            this.total_insurance_price += this.list[i].insurance_price;
            this.total_other_product_price += this.list[i].other_product_price;
            this.total_sales += this.list[i].net_price;
            this.number_char_1 = this.list[i].number.charAt(0);
            console.log(this.number_char_1);
            if (this.list[i].car_type == "CT1" && this.number_char_1 == "R") {
              this.total_ct1 ++;
            } else if (this.list[i].car_type == "CT2" && this.number_char_1 == "R") {
              this.total_ct2 ++;
            } else if (this.list[i].car_type == "CT3" && this.number_char_1 == "R") {
              this.total_ct3 ++;
            } else if (this.list[i].car_type == "CT4" && this.number_char_1 == "R") {
              this.total_ct4 ++;
            } else if (this.number_char_1 == "R"){
              this.total_ct_other ++;
            }
          }
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    async download_excel() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/report/download_total_sales_report`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          if (
            moment(this.filter.start_date).format('DDMMYYYY') !=
            moment(this.filter.end_date).format('DDMMYYYY')
          ) {
            this.forceFileDownload(
              response,
              'ยอดขายรวม' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '_' +
                moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          } else {
            this.forceFileDownload(
              response,
              'ยอดขายรวม' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async download_daily_summary_excel() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/report/download_daily_summary_report`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          if (
            moment(this.filter.start_date).format('DDMMYYYY') !=
            moment(this.filter.end_date).format('DDMMYYYY')
          ) {
            this.forceFileDownload(
              response,
              'ยอดขายรวมสรุปรายวัน' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '_' +
                moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          } else {
            this.forceFileDownload(
              response,
              'ยอดขายรวม' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async download_detail_excel() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/report/download_total_sales_report_detail`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          if (
            moment(this.filter.start_date).format('DDMMYYYY') !=
            moment(this.filter.end_date).format('DDMMYYYY')
          ) {
            this.forceFileDownload(
              response,
              'ยอดขายรวม' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '_' +
                moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          } else {
            this.forceFileDownload(
              response,
              'ยอดขายรวม' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },

    onSaveMonth(date) {
      this.$refs.menu.save(date);
      this.filter.start_date = moment(date, 'YYYY-MM')
        .startOf('month')
        .format('YYYY-MM-DD');
      this.filter.end_date = moment(date, 'YYYY-MM')
        .endOf('month')
        .format('YYYY-MM-DD');
      console.log(this.filter);
    },
    getMonth(date) {
      return moment(date).format('YYYY-MM');
    },

    edit(item) {
      this.$router.push({
        name: 'document-receipt-update',
        params: { id: item._id },
        // query: { download: true }
      });
    },
  },
  watch: {
    timeRangeType: function (value, oldValue) {
      console.log(value);
      if (value == 0) {
        // 0 = เดือนปัจจุบัน
        this.filter.start_date = moment().startOf('month').format('YYYY-MM-DD');
        this.filter.end_date = moment().endOf('month').format('YYYY-MM-DD');
        console.log(this.filter);
      } else if (value == 1) {
        // 1 = เลือกช่วงวันที่
        this.filter.start_date = moment().startOf('month').format('YYYY-MM-DD');
        this.filter.end_date = moment().endOf('month').format('YYYY-MM-DD');
        console.log(this.filter);
      } else if (value == 2) {
        // 2 = เดือนก่อน
        this.filter.start_date = moment()
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD');
        this.filter.end_date = moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD');
        this.month = moment().subtract(1, 'months').format('YYYY-MM');
        console.log(this.filter);
      } else if (value == 3) {
        // 3 = วันนี้
        this.filter.start_date = moment().format('YYYY-MM-DD');
        this.filter.end_date = this.filter.start_date;
        console.log(this.filter);
      }
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
